import cornerstoneTools from "cornerstone-tools";
import cornerstone, { CanvasCoordinate } from "cornerstone-core";
import Drawing from "../../api/Drawing";
import Manipulators from "../../api/Manipulators";
import { MEASUREMENT_SUFFIXES, TOOL_IDS } from "../../consts/tools.consts";
import { getDimensionData } from "../../measurement-tools/measurementToolUtils";
import { formatHandles } from "./formatHandles";
import { setToolInstructions } from "./setToolInstructions";
import {
  GONSTEAD_CONFIGURATION,
  GONSTEAD_POINTS,
  GONSTEAD_POINTS_ARRAY,
} from "./gonsteadTool.consts";
import { ExtendedAnnotationTool } from "../../api/ExtendedAnnotationTool";
import {
  arePointsPlaced,
  drawGonsteadLine,
  drawMeasurementTextBox,
  getHandleName,
  getLastPlacedHandle,
  getNextGonsteadPoint,
  getTextboxHandleName,
  getTextboxOffset,
  getTextboxValue,
} from "./helpers";
import { calculateGradient } from "../../measurement-tools/curve-radius/curveRadiusFunctions";
import {
  calculateIliacCrestData,
  calculateIliacCrestToTuberosityDistance,
  calculateIliacWingPSISData,
  calculateIschialTuberosityData,
  calculateMeasuredDeficiency,
  calculateSacralAlaData,
  calculateSacralAlaToTubercleData,
  calculateTubercleLine,
  calculateTubercleToPubicSymphysis,
} from "./formatLines";

const drawLinkedTextBox = cornerstoneTools.import("drawing/drawLinkedTextBox");

export class GonsteadTool extends ExtendedAnnotationTool {
  hasIncomplete: boolean;
  preventNewMeasurement: boolean;
  currentPoint: string;
  isActive: boolean;
  displayLabels: boolean;
  element: null | HTMLElement;
  constructor() {
    super(TOOL_IDS.GONSTEAD_TOOL);
    this.hasIncomplete = false;
    this.preventNewMeasurement = false;
    this.currentPoint = null;
    this.isActive = false;
    this.displayLabels = false;
    this.element = null;
  }

  public onToolActivation(element) {
    setToolInstructions(GONSTEAD_POINTS.RIGHT_FEMUR_HEAD);
    this.currentPoint = GONSTEAD_POINTS.RIGHT_FEMUR_HEAD;
    this.isActive = true;

    this.element = element;
    document.addEventListener("keydown", this.handleKeyDown);
  }
  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Backspace") {
      this.backspacePressed();
    }
  };

  private backspacePressed() {
    const { element } = cornerstone.getEnabledElement(
      this.element as HTMLElement
    );
    if (!element) return;

    this.currentPoint = GONSTEAD_POINTS.RIGHT_FEMUR_HEAD;
    setToolInstructions(GONSTEAD_POINTS.RIGHT_FEMUR_HEAD);
    const toolStateManager = cornerstoneTools.getToolState(
      element,
      TOOL_IDS.GONSTEAD_TOOL
    );

    if (toolStateManager && toolStateManager.data.length) {
      const toolState = toolStateManager.data[toolStateManager.data.length - 1];
      const lastPlacedPoint = getLastPlacedHandle(toolState.handles);

      const currentHandleName = getHandleName(lastPlacedPoint);
      toolState.handles[currentHandleName] = {
        ...toolState.handles[currentHandleName],
        active: false,
        placed: false,
      };

      cornerstone.updateImage(element);
      this.currentPoint = lastPlacedPoint;
      setToolInstructions(lastPlacedPoint);
    }
  }

  public onToolDeactivation(_element) {
    this.isActive = false;
    this.currentPoint = null;
    this.preventNewMeasurement = true;
    setToolInstructions("");
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  createNewMeasurement(eventData) {
    if (this.isActive) {
      this.hasIncomplete = true;
      const goodEventData =
        eventData && eventData.currentPoints && eventData.currentPoints.image;
      if (!goodEventData) {
        console.error(
          `required eventData not supplied to tool ${this.toolId}'s createNewMeasurement`
        );
        return;
      }
      const currentImagePointData = eventData.currentPoints.image;

      const handleData = formatHandles(currentImagePointData);

      return handleData;
    }
  }

  //Check whether each handle is inside the image boundary and update handle insideImage parameter if not
  activeCallback(element) {
    if (this.isActive) {
      this.onMeasureModified = this.onMeasureModified.bind(this);
      element.addEventListener(
        cornerstoneTools.EVENTS.MEASUREMENT_MODIFIED,
        this.onMeasureModified
      );
    }
  }

  onMeasureModified(evt) {
    const { element } = evt.detail;
    const image = cornerstone.getEnabledElement(element).image;
    const handles = evt.detail.measurementData.handles;

    //Go through handle list and check if any of the coordinates are outside image boundary. If so, change the state of the handle

    if (handles.length <= 0) {
      return;
    }

    const xLeftBorder = 0;
    const xRightBorder = image.width;
    const yTopBorder = 0;
    const yBottomBorder = image.height;
    //For placed handles
    Object.keys(handles).map((key) => {
      let handle = handles[key];
      if (!key.includes("textBox")) {
        if (
          handle.x < xLeftBorder ||
          handle.x > xRightBorder ||
          handle.y < yTopBorder ||
          handle.y > yBottomBorder
        ) {
          handle.insideImage = false;
        } else {
          handle.insideImage = true;
        }
      }
    });

    //For the pre-placement handle
    const prePlacementHandle =
      evt?.detail?.measurementData?.prePlacementHandles?.handle;
    if (!prePlacementHandle) {
      return;
    }
    if (
      prePlacementHandle.x < xLeftBorder ||
      prePlacementHandle.x > xRightBorder ||
      prePlacementHandle.y < yTopBorder ||
      prePlacementHandle.y > yBottomBorder
    ) {
      prePlacementHandle.insideImage = false;
    } else {
      prePlacementHandle.insideImage = true;
    }
  }

  updateCachedStats(image, _element, data) {
    const { rowPixelSpacing, colPixelSpacing } = getDimensionData(
      image,
      this.imageMetaData
    );
    data.rowPixelSpacing = rowPixelSpacing;
    data.colPixelSpacing = colPixelSpacing;
  }

  addNewMeasurement(evt, interactionType) {
    evt.preventDefault();
    evt.stopPropagation();
    setToolInstructions(GONSTEAD_POINTS.LEFT_FEMUR_HEAD);
    this.currentPoint = GONSTEAD_POINTS.LEFT_FEMUR_HEAD;

    const eventData = evt.detail;
    const measurementData = this.createNewMeasurement(eventData);
    const element = evt.detail.element;

    const toolInstructions = document.getElementById("toolInstructions");
    cornerstoneTools.addToolState(element, this.toolId, measurementData);
    cornerstone.updateImage(element);

    const getManipulator = (gonsteadPoint: string) => {
      const handleName = getHandleName(gonsteadPoint);
      const textboxHandleName = getTextboxHandleName(gonsteadPoint);
      const nextPoint = getNextGonsteadPoint(gonsteadPoint);

      const onMovedCallback = (success, handleData) => {
        if (this.currentPoint === gonsteadPoint) {
          measurementData.active = false;
          measurementData.handles[handleName].placed = true;
          measurementData.handles[textboxHandleName].placed = true;
          setToolInstructions(nextPoint);
          cornerstone.updateImage(element);
          this.currentPoint = nextPoint;

          if (!success) {
            cornerstoneTools.removeToolState(
              element,
              this.toolId,
              measurementData
            );
            this.preventNewMeasurement = false;
            toolInstructions.innerHTML = "";
            return;
          }
          if (nextPoint) {
            getManipulator(nextPoint);
          }
        } else {
          const clickedPointHandleName = getHandleName(this.currentPoint);
          const clickedTextbox = getTextboxHandleName(this.currentPoint);
          const updatedNextPoint = getNextGonsteadPoint(this.currentPoint);
          measurementData.active = false;
          measurementData.handles[clickedPointHandleName].placed = true;
          measurementData.handles[clickedTextbox].placed = true;
          measurementData.handles[clickedPointHandleName].x = handleData.x;
          measurementData.handles[clickedPointHandleName].y = handleData.y;
          setToolInstructions(updatedNextPoint);
          cornerstone.updateImage(element);
          this.currentPoint = updatedNextPoint;
          getManipulator(updatedNextPoint);
        }
      };

      Manipulators.moveNewHandle(
        eventData,
        this.toolId,
        measurementData,
        measurementData.handles[handleName],
        {},
        interactionType,
        (success) =>
          onMovedCallback(success, measurementData.handles[handleName])
      );
    };
    const currentImagePointData = eventData.currentPoints.image;
    measurementData.handles.rightFemurHeadHandle.placed = true;
    measurementData.handles.rightFemurHeadHandle.x = currentImagePointData.x;
    measurementData.handles.rightFemurHeadHandle.y = currentImagePointData.y;
    getManipulator(GONSTEAD_POINTS_ARRAY[1]);
  }

  pointNearTool(element, data, coords) {
    let nearTool = false;
    const validParameters = data && data.handles;
    if (!validParameters) {
      return false;
    }

    if (data.visible === false) {
      return false;
    }

    const { lines } = this.getToolGeometry(element, data);

    Object.keys(lines).forEach((key) => {
      let distance = 1000;

      //Get the coordinates for the calculations from the line coordinates and the current cursor coordinates
      const { x: x1, y: y1 } = lines[key].start;
      const { x: x2, y: y2 } = lines[key].end;
      const { x, y } = coords;
      //Calculate squared length of the line segment
      const squaredLength = Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2);

      if (squaredLength === 0) {
        //Line segment is a point
        distance = Math.sqrt(Math.pow(x - x1, 2) + Math.pow(y - y1, 2));
      }

      //Calculate the projection parameter
      const projection =
        ((x - x1) * (x2 - x1) + (y - y1) * (y2 - y1)) / squaredLength;

      if (projection < 0) {
        //Closest point is the start point
        distance = Math.sqrt(Math.pow(x - x1, 2) + Math.pow(y - y1, 2));
      } else if (projection > 1) {
        //Closest point is the end point
        distance = Math.sqrt(Math.pow(x - x2, 2) + Math.pow(y - y2, 2));
      } else {
        //Closest point lies on the line segment
        const px = x1 + projection * (x2 - x1);
        const py = y1 + projection * (y2 - y1);
        distance = Math.sqrt(Math.pow(x - px, 2) + Math.pow(y - py, 2));
      }
      if (distance < 5) {
        nearTool = true;
      }
    });

    return nearTool;
  }

  getToolGeometry(element, data) {
    const handleCanvasPoints = Object.values(GONSTEAD_POINTS).map(
      (pointKey) => {
        const handleName = getHandleName(pointKey);
        return {
          [pointKey]: cornerstone.pixelToCanvas(
            element,
            data.handles[handleName]
          ),
        };
      }
    );

    // Convert the array of objects into a single object
    const handleCanvasMap = Object.assign({}, ...handleCanvasPoints);
    const { rowPixelSpacing, colPixelSpacing } = data;
    const pixelSpacing = { rowPixelSpacing, colPixelSpacing };
    // Use the handles correctly in calculations
    const fhlGradient = calculateGradient(
      handleCanvasMap.leftFemurHead,
      handleCanvasMap.rightFemurHead
    );
    const fhlPerpGradient = -1 / fhlGradient;
    const fhlLength = Math.hypot(
      handleCanvasMap.leftFemurHead.x - handleCanvasMap.rightFemurHead.x,
      handleCanvasMap.leftFemurHead.y - handleCanvasMap.rightFemurHead.y
    );

    const extLength = fhlLength / 8;
    const xExt = Math.sqrt(extLength ** 2 / (fhlGradient ** 2 + 1));
    const xExtPerp = Math.sqrt(extLength ** 2 / (fhlPerpGradient ** 2 + 1));
    const extLengthLong = fhlLength / 2;

    const sblGradient = calculateGradient(
      handleCanvasMap.leftS1SacralGroove,
      handleCanvasMap.rightS1SacralGroove
    );

    const sblxExt = Math.sqrt(extLengthLong ** 2 / (sblGradient ** 2 + 1));

    const tubercleData = calculateTubercleLine(
      handleCanvasMap,
      fhlGradient,
      fhlPerpGradient,
      sblGradient,
      xExtPerp
    );

    const rightSacralAlaData = calculateSacralAlaData(
      handleCanvasMap,
      "right",
      fhlPerpGradient,
      sblGradient,
      fhlGradient,
      xExtPerp
    );
    const leftSacralAlaData = calculateSacralAlaData(
      handleCanvasMap,
      "left",
      fhlPerpGradient,
      sblGradient,
      fhlGradient,
      xExtPerp
    );
    const { measuredDeficiencyLine, mdPoint, measuredDeficiencyDistance } =
      calculateMeasuredDeficiency(handleCanvasMap, xExt, element, pixelSpacing);

    const rightIliacCrestData = calculateIliacCrestData(
      handleCanvasMap,
      "right",
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );
    const leftIliacCrestData = calculateIliacCrestData(
      handleCanvasMap,
      "left",
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );
    const leftIschialTuberosityData = calculateIschialTuberosityData(
      handleCanvasMap,
      "left",
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );
    const rightIschialTuberosityData = calculateIschialTuberosityData(
      handleCanvasMap,
      "right",
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );
    const lines = {
      fhl: {
        start: {
          x: handleCanvasMap.rightFemurHead.x - xExt,
          y: handleCanvasMap.rightFemurHead.y - fhlGradient * xExt,
        },
        end: {
          x: handleCanvasMap.leftFemurHead.x + xExt,
          y: handleCanvasMap.leftFemurHead.y + fhlGradient * xExt,
        },
      },
      iliacCrestR: {
        start: {
          x: handleCanvasMap.rightIliacCrest.x - xExt,
          y: handleCanvasMap.rightIliacCrest.y - fhlGradient * xExt,
        },
        end: {
          x: handleCanvasMap.rightIliacCrest.x + xExt,
          y: handleCanvasMap.rightIliacCrest.y + fhlGradient * xExt,
        },
      },
      iliacCrestL: {
        start: {
          x: handleCanvasMap.leftIliacCrest.x - xExt,
          y: handleCanvasMap.leftIliacCrest.y - fhlGradient * xExt,
        },
        end: {
          x: handleCanvasMap.leftIliacCrest.x + xExt,
          y: handleCanvasMap.leftIliacCrest.y + fhlGradient * xExt,
        },
      },
      ischialTuberosityR: {
        start: {
          x: handleCanvasMap.rightIschialTuberosity.x - xExt,
          y: handleCanvasMap.rightIschialTuberosity.y - fhlGradient * xExt,
        },
        end: {
          x: handleCanvasMap.rightIschialTuberosity.x + xExt,
          y: handleCanvasMap.rightIschialTuberosity.y + fhlGradient * xExt,
        },
      },
      ischialTuberosityL: {
        start: {
          x: handleCanvasMap.leftIschialTuberosity.x - xExt,
          y: handleCanvasMap.leftIschialTuberosity.y - fhlGradient * xExt,
        },
        end: {
          x: handleCanvasMap.leftIschialTuberosity.x + xExt,
          y: handleCanvasMap.leftIschialTuberosity.y + fhlGradient * xExt,
        },
      },
      pubicSymphysis: {
        start: {
          x: handleCanvasMap.pubicSymphysis.x - xExtPerp,
          y: handleCanvasMap.pubicSymphysis.y - fhlPerpGradient * xExtPerp,
        },
        end: {
          x: handleCanvasMap.pubicSymphysis.x + xExtPerp,
          y: handleCanvasMap.pubicSymphysis.y + fhlPerpGradient * xExtPerp,
        },
      },
      sbl: {
        start: {
          x: handleCanvasMap.rightS1SacralGroove.x - sblxExt,
          y: handleCanvasMap.rightS1SacralGroove.y - sblGradient * sblxExt,
        },
        end: {
          x: handleCanvasMap.leftS1SacralGroove.x + sblxExt,
          y: handleCanvasMap.leftS1SacralGroove.y + sblGradient * sblxExt,
        },
      },
      rightSacralAla: rightSacralAlaData.sacralAlaLine,
      leftSacralAla: leftSacralAlaData.sacralAlaLine,
      measuredDeficiencyLine,
      rightIliacWing: {
        start: {
          x: handleCanvasMap.rightIliacWing.x - xExtPerp,
          y: handleCanvasMap.rightIliacWing.y - fhlPerpGradient * xExtPerp,
        },
        end: {
          x: handleCanvasMap.rightIliacWing.x + xExtPerp,
          y: handleCanvasMap.rightIliacWing.y + fhlPerpGradient * xExtPerp,
        },
      },
      leftIliacWing: {
        start: {
          x: handleCanvasMap.leftIliacWing.x - xExtPerp,
          y: handleCanvasMap.leftIliacWing.y - fhlPerpGradient * xExtPerp,
        },
        end: {
          x: handleCanvasMap.leftIliacWing.x + xExtPerp,
          y: handleCanvasMap.leftIliacWing.y + fhlPerpGradient * xExtPerp,
        },
      },
      rightPSIS: {
        start: {
          x: handleCanvasMap.rightPSIS.x - xExtPerp,
          y: handleCanvasMap.rightPSIS.y - fhlPerpGradient * xExtPerp,
        },
        end: {
          x: handleCanvasMap.rightPSIS.x + xExtPerp,
          y: handleCanvasMap.rightPSIS.y + fhlPerpGradient * xExtPerp,
        },
      },
      leftPSIS: {
        start: {
          x: handleCanvasMap.leftPSIS.x - xExtPerp,
          y: handleCanvasMap.leftPSIS.y - fhlPerpGradient * xExtPerp,
        },
        end: {
          x: handleCanvasMap.leftPSIS.x + xExtPerp,
          y: handleCanvasMap.leftPSIS.y + fhlPerpGradient * xExtPerp,
        },
      },
      s2Tubercle: tubercleData.s2Tubercle,
    };

    const leftIliacCrestToIschialTuberosity =
      calculateIliacCrestToTuberosityDistance(
        leftIliacCrestData.iliacCrestPerpendicularPoint,
        leftIschialTuberosityData.perpendicularPointIschialTuberosity,
        element,
        pixelSpacing
      );
    const rightIliacCrestToIschialTuberosity =
      calculateIliacCrestToTuberosityDistance(
        rightIliacCrestData.iliacCrestPerpendicularPoint,
        rightIschialTuberosityData.perpendicularPointIschialTuberosity,
        element,
        pixelSpacing
      );

    const {
      perpendicularPointS2TuberclePubicSymphysis,
      distanceS2TuberclePubicSymphysis,
    } = calculateTubercleToPubicSymphysis(
      handleCanvasMap,
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );

    //Calculate the distances between the calculated points on the SBL
    const rightSacralToTubercleDistance = calculateSacralAlaToTubercleData(
      rightSacralAlaData.sacralAlaStartPoint,
      tubercleData.tubercleStartPoint,
      element,
      pixelSpacing
    );
    const leftSacralToTubercleDistance = calculateSacralAlaToTubercleData(
      leftSacralAlaData.sacralAlaStartPoint,
      tubercleData.tubercleStartPoint,
      element,
      pixelSpacing
    );

    //Calculate the perpendicular points and distances between the Iliac Wing and Posterior Superior Iliac Spine lines
    const rightIlaiacWingPSISData = calculateIliacWingPSISData(
      handleCanvasMap,
      "right",
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );
    const leftIlaiacWingPSISData = calculateIliacWingPSISData(
      handleCanvasMap,
      "left",
      fhlGradient,
      fhlPerpGradient,
      element,
      pixelSpacing
    );
    const points = {
      mdPoint,
      leftIliacWing: leftIlaiacWingPSISData.perpendicularPoint,
      rightIliacWing: rightIlaiacWingPSISData.perpendicularPoint,
      perpendicularPointS2TuberclePubicSymphysis,
      leftIliacCrest: leftIliacCrestData.iliacCrestPerpendicularPoint,
      rightIliacCrest: rightIliacCrestData.iliacCrestPerpendicularPoint,
      rightSacralAlaStart: rightSacralAlaData.sacralAlaStartPoint,
      leftSacralAlaStart: leftSacralAlaData.sacralAlaStartPoint,
      leftIschialTuberosity:
        leftIschialTuberosityData.perpendicularPointIschialTuberosity,
      rightIschialTuberosity:
        rightIschialTuberosityData.perpendicularPointIschialTuberosity,
      tubercle: tubercleData.tubercleStartPoint,
    };

    const distances = {
      leftIliacCrest: leftIliacCrestData.distanceIliacCrestLineFHL,
      rightIliacCrest: rightIliacCrestData.distanceIliacCrestLineFHL,
      leftIliacCrestToIschialTuberosity,
      rightIliacCrestToIschialTuberosity,
      distanceS2TuberclePubicSymphysis,
      rightSacralToTubercleDistance,
      leftIlaiacWingPSISDistance:
        leftIlaiacWingPSISData.distanceIliacWingLineToPSISLine,
      rightIlaiacWingPSISDistance:
        rightIlaiacWingPSISData.distanceIliacWingLineToPSISLine,
      leftSacralToTubercleDistance,
      measuredDeficiencyDistance,
    };
    return {
      lines,
      points,
      distances,
    };
  }

  drawToolData(element, context, toolData) {
    const {
      handleRadius,
      prePlacementHandleRadius,
      circleColour,
      circleColourOutsideImage,
      labelColour, //'white'
      lineColour, //'lime',
      lineColourOutsideImage,
    } = GONSTEAD_CONFIGURATION;

    let allHandlesInsideImage = true;

    Drawing.draw(context, (context) => {
      const {
        visible,
        handles,
        prePlacementHandles,
        rowPixelSpacing,
        colPixelSpacing,
      } = toolData;

      if (visible) {
        //Draw the pre-placement handle
        if (handles.leftPSISHandle.placed === false) {
          //If all the handles are placed (i.e. the last handle, leftPSISHandle, is placed), then don't draw the pre-placement handle
          Drawing.drawHandles(context, { element }, prePlacementHandles, {
            handleRadius: prePlacementHandleRadius,
            color: prePlacementHandles.handle.insideImage
              ? circleColour
              : circleColourOutsideImage,
          });
        }

        const toolGeometry = this.getToolGeometry(element, toolData);
        const { lines, distances, points } = toolGeometry;

        GONSTEAD_POINTS_ARRAY.map((gonsteadPoint) => {
          const handleName = getHandleName(gonsteadPoint);
          const textboxHandleName = getTextboxHandleName(gonsteadPoint);
          const handle = handles[handleName];
          const textboxHandle = handles[textboxHandleName];

          if (!handle.insideImage) {
            allHandlesInsideImage = false;
          }

          // If the handle is a handle point (not a textBox)
          if (handle.placed === true) {
            Drawing.drawCircle(
              context,
              element,
              handle,
              handleRadius,
              {
                color: handle.insideImage
                  ? circleColour
                  : circleColourOutsideImage,
              },
              "pixel"
            );
            const formattedTextbox = getTextboxOffset(
              handle,
              textboxHandle,
              gonsteadPoint
            );

            if (formattedTextbox !== undefined) {
              drawLinkedTextBox(
                context,
                element,
                handle,
                handle.label, //The text to display in the textBox.
                handles, //The handles of the annotation.
                function () {
                  return [formattedTextbox];
                },
                labelColour, //'white', //The link color.
                2, //The line width of the link.
                0, //The x offset of the textBox.
                false //Vertically centers the text if true.
              );
            }

            const lineOptions = allHandlesInsideImage
              ? { color: lineColour }
              : { color: lineColourOutsideImage };

            //Draw the FHL line
            if (
              arePointsPlaced(handles, [
                GONSTEAD_POINTS.LEFT_FEMUR_HEAD,
                GONSTEAD_POINTS.RIGHT_FEMUR_HEAD,
              ])
            ) {
              drawGonsteadLine(element, context, lines.fhl, lineOptions);
            }
            //Draw the right Iliac Crest Line (through rightIliacCrestHandle, parallel to FHL)
            if (handles.rightIliacCrestHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.iliacCrestR,
                lineOptions
              );
            }
            //Draw the right Ischial Tuberosity Line (through rightIschialTuberosityHandle, parallel to FHL)
            if (handles.rightIschialTuberosityHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.ischialTuberosityR,
                lineColour
              );
            }
            //Draw the left Iliac Crest Line (through leftIliacCrestHandle, parallel to FHL)
            if (handles.leftIliacCrestHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.iliacCrestL,
                lineOptions
              );
            }
            //Draw the left Ischial Tuberosity Line (through leftIschialTuberosityHandle, parallel to FHL)
            if (handles.leftIschialTuberosityHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.ischialTuberosityL,
                lineOptions
              );
            }
            //Draw the S2 Tubercle Line (through s2TubercleHandle, perpendicular to FHL)
            if (
              arePointsPlaced(handles, [
                GONSTEAD_POINTS.S2_TUBERCLE,
                GONSTEAD_POINTS.PUBIC_SYMPHYSIS,
                GONSTEAD_POINTS.RIGHT_S1_SACRAL_GROOVE,
                GONSTEAD_POINTS.LEFT_S1_SACRAL_GROOVE,
              ])
            ) {
              drawGonsteadLine(element, context, lines.s2Tubercle, lineOptions);
            }
            //Draw the Pubic SymphysisLine Line (through pubicSymphysisHandle, perpendicular to FHL)
            if (handles.pubicSymphysisHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.pubicSymphysis,
                lineOptions
              );
            }
            //Draw the SBL line
            if (
              arePointsPlaced(handles, [
                GONSTEAD_POINTS.RIGHT_S1_SACRAL_GROOVE,
                GONSTEAD_POINTS.LEFT_S1_SACRAL_GROOVE,
              ])
            ) {
              drawGonsteadLine(element, context, lines.sbl, lineOptions);
            }
            // Draw the Left Sacral Ala line
            if (handles.leftSacralAlaHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.leftSacralAla,
                lineOptions
              );
            }

            // Draw the Measured Deficiency Line
            if (handles.leftSacralAlaHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.measuredDeficiencyLine,
                lineOptions
              );
            }

            // Draw the Right Iliac Wing Line (through rightIliacWingHandle, perpendicular to FHL)
            if (handles.rightIliacWingHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.rightIliacWing,
                lineOptions
              );
            }

            // Draw the Left Iliac Wing Line (through rightIliacWingHandle, perpendicular to FHL)
            if (handles.leftIliacWingHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.leftIliacWing,
                lineOptions
              );
            }

            // Draw the Right Posterior Superior Iliac Spine Line (through rightPSISHandle, perpendicular to FHL)
            if (handles.rightPSISHandle.placed == true) {
              drawGonsteadLine(element, context, lines.rightPSIS, lineOptions);
            }

            // Draw the Left Posterior Superior Iliac Spine Line (through leftPSISHandle, perpendicular to FHL)
            if (handles.leftPSISHandle.placed == true) {
              drawGonsteadLine(element, context, lines.leftPSIS, lineOptions);
            }
            // Draw the Right Sacral Ala line
            if (handles.rightSacralAlaHandle.placed == true) {
              drawGonsteadLine(
                element,
                context,
                lines.rightSacralAla,
                lineOptions
              );
            }

            const suffix =
              !rowPixelSpacing || !colPixelSpacing
                ? MEASUREMENT_SUFFIXES.PIXELS
                : MEASUREMENT_SUFFIXES.MM;

            //Draw MD measurement text box
            if (handles.leftFemurHeadHandle.placed === true) {
              const mdPointPixel = cornerstone.canvasToPixel(
                element,
                points.mdPoint as CanvasCoordinate
              );
              if (!handles.textBoxMD.hasMoved) {
                handles.textBoxMD.x = mdPointPixel.x;
                handles.textBoxMD.y = mdPointPixel.y - 80;
              }

              const textboxValue = getTextboxValue(
                distances.measuredDeficiencyDistance,
                suffix,
                "MD"
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBoxMD,
                textboxValue,
                handles,
                [mdPointPixel]
              );
            }
            //Draw Right Iliac Crest to Ischail Tuberosity measurement textBox
            if (handles.rightIschialTuberosityHandle.placed == true) {
              if (
                !handles.textBox_iliacCrestRLine_ischialTuberosityRLine.hasMoved
              ) {
                handles.textBox_iliacCrestRLine_ischialTuberosityRLine.x =
                  handles.rightIliacCrestHandle.x - 350;
                handles.textBox_iliacCrestRLine_ischialTuberosityRLine.y =
                  handles.rightIliacCrestHandle.y - 15;
              }
              const title = this.displayLabels
                ? "Right Iliac Crest To Ischial Tuberosity"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.rightIliacCrestToIschialTuberosity,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_iliacCrestRLine_ischialTuberosityRLine,
                textboxValue,
                handles,
                [
                  handles.rightIliacCrestHandle,
                  handles.rightIschialTuberosityHandle,
                ]
              );
            }
            //Draw Left Iliac Crest to Ischail Tuberosity measurement textBox
            if (handles.leftIschialTuberosityHandle.placed == true) {
              if (
                !handles.textBox_iliacCrestLLine_ischialTuberosityLLine.hasMoved
              ) {
                handles.textBox_iliacCrestLLine_ischialTuberosityLLine.x =
                  handles.leftIliacCrestHandle.x + 50;
                handles.textBox_iliacCrestLLine_ischialTuberosityLLine.y =
                  handles.leftIliacCrestHandle.y - 15;
              }
              const title = this.displayLabels
                ? "Left Iliac Crest To Ischial Tuberosity"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.leftIliacCrestToIschialTuberosity,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_iliacCrestLLine_ischialTuberosityLLine,
                textboxValue,
                handles,
                [
                  handles.leftIliacCrestHandle,
                  handles.leftIschialTuberosityHandle,
                ]
              );
            }
            //Draw S2 Tubercle to Pubic Symphysis measurement textBox
            if (handles.pubicSymphysisHandle.placed == true) {
              if (!handles.textBox_S2TubercleLine_pubicSymphysisLine.hasMoved) {
                handles.textBox_S2TubercleLine_pubicSymphysisLine.x =
                  handles.pubicSymphysisHandle.x + 50;
                handles.textBox_S2TubercleLine_pubicSymphysisLine.y =
                  handles.pubicSymphysisHandle.y - 15;
              }
              const title = this.displayLabels
                ? "S2Tubercle To Public Symphysis"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.distanceS2TuberclePubicSymphysis,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_S2TubercleLine_pubicSymphysisLine,
                textboxValue,
                handles,
                [handles.pubicSymphysisHandle]
              );
            }
            //Draw Right Sacral Ala to S2 Tubercle measurement textBox
            if (handles.rightSacralAlaHandle.placed == true) {
              if (
                !handles.textBox_rightSacralAlaOnSbl_s2TubercleOnSbl.hasMoved
              ) {
                handles.textBox_rightSacralAlaOnSbl_s2TubercleOnSbl.x =
                  handles.rightSacralAlaHandle.x - 350;
                handles.textBox_rightSacralAlaOnSbl_s2TubercleOnSbl.y =
                  handles.rightSacralAlaHandle.y - 15;
              }
              const title = this.displayLabels
                ? "R Sacral Ala To Tubercle"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.rightSacralToTubercleDistance,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_rightSacralAlaOnSbl_s2TubercleOnSbl,
                textboxValue,
                handles,
                [points.rightSacralAlaStart, points.tubercle]
              );
            }
            //Draw Left Sacral Ala to S2 Tuberosity measurement textBox
            if (handles.leftSacralAlaHandle.placed == true) {
              if (
                !handles.textBox_leftSacralAlaOnSbl_s2TubercleOnSbl.hasMoved
              ) {
                handles.textBox_leftSacralAlaOnSbl_s2TubercleOnSbl.x =
                  handles.leftSacralAlaHandle.x + 50;
                handles.textBox_leftSacralAlaOnSbl_s2TubercleOnSbl.y =
                  handles.leftSacralAlaHandle.y - 15;
              }
              const title = this.displayLabels
                ? "L Sacral Ala To Tubercle"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.leftSacralToTubercleDistance,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_leftSacralAlaOnSbl_s2TubercleOnSbl,
                textboxValue,
                handles,
                [points.leftSacralAlaStart, points.tubercle]
              );
            }
            //Draw Right Iliac Wing to PSIS measurement textBox
            if (handles.rightPSISHandle.placed == true) {
              if (!handles.textBox_rightIliacWingLine_rightPSISLine.hasMoved) {
                handles.textBox_rightIliacWingLine_rightPSISLine.x =
                  handles.rightIliacWingHandle.x - 350;
                handles.textBox_rightIliacWingLine_rightPSISLine.y =
                  handles.rightIliacWingHandle.y - 15;
              }
              const title = this.displayLabels
                ? "R Ilaiac Wing To PSIS"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.rightIlaiacWingPSISDistance,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_rightIliacWingLine_rightPSISLine,
                textboxValue,
                handles,
                [points.rightIliacWing, handles.rightPSISHandle]
              );
            }
            //Draw Left Iliac Wing to PSIS measurement textBox
            if (handles.leftPSISHandle.placed == true) {
              if (!handles.textBox_leftIliacWingLine_leftPSISLine.hasMoved) {
                handles.textBox_leftIliacWingLine_leftPSISLine.x =
                  handles.leftIliacWingHandle.x + 50;
                handles.textBox_leftIliacWingLine_leftPSISLine.y =
                  handles.leftIliacWingHandle.y - 15;
              }
              const title = this.displayLabels
                ? "L Ilaiac Wing To PSIS"
                : undefined;
              const textboxValue = getTextboxValue(
                distances.leftIlaiacWingPSISDistance,
                suffix,
                title
              );
              drawMeasurementTextBox(
                context,
                element,
                handles.textBox_leftIliacWingLine_leftPSISLine,
                textboxValue,
                handles,
                [points.leftIliacWing, handles.leftPSISHandle]
              );
            }
          }
        });
      }
    });
  }
}
