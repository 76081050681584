import cornerstoneTools from "cornerstone-tools";
import Drawing from "../../api/Drawing";
import {
  GONSTEAD_CONFIGURATION,
  GONSTEAD_POINTS_ARRAY,
  Y_OFFSET_POINTS,
} from "./gonsteadTool.consts";
import { getDistance } from "../../measurement-tools/measurementToolUtils";

const drawLinkedTextBox = cornerstoneTools.import("drawing/drawLinkedTextBox");

export const getLastPlacedHandle = (handles) => {
  const lastPlacedPoint = GONSTEAD_POINTS_ARRAY.find((point) => {
    const handleName = getHandleName(point);
    const nextPoint = getNextGonsteadPoint(point);
    const nextHandleName = getHandleName(nextPoint);
    const isLastHandlePlaced =
      handles[handleName].placed === true &&
      handles[nextHandleName].placed === false;
    if (isLastHandlePlaced) {
      return point;
    }
  });
  return lastPlacedPoint;
};
export const getNextGonsteadPoint = (currentGonsteadPoint: string) => {
  const currentIndex = GONSTEAD_POINTS_ARRAY.findIndex(
    (point) => point === currentGonsteadPoint
  );
  if (currentIndex === -1 || currentIndex === GONSTEAD_POINTS_ARRAY.length) {
    return null;
  }

  return GONSTEAD_POINTS_ARRAY[currentIndex + 1];
};

export const getHandleName = (currentGonsteadPoint: string) =>
  `${currentGonsteadPoint}Handle`;

export const getTextboxHandleName = (currentGonsteadPoint: string) =>
  `${currentGonsteadPoint}TextBox`;

export const getTextboxOffset = (handle, textboxHandle, gonsteadPoint) => {
  if (!handle.hasMoved && handle.boundingBox) {
    const textBoxWidth = handle.boundingBox.width; ///pixelsPerCanvasPixel;
    let xOffset = 0;

    if (handle.label.includes("R")) {
      xOffset = -3.5 * textBoxWidth; //-500;
    } else if (handle.label.includes("L")) {
      xOffset = textBoxWidth / 2; //100;//1;
    } else {
      xOffset = -1.5 * textBoxWidth; //250;
    }

    const yOffset = Y_OFFSET_POINTS.includes(gonsteadPoint) ? -100 : 40;
    textboxHandle.x = handle.x + xOffset;
    textboxHandle.y = handle.y + yOffset;
  }
  return textboxHandle;
};

export const arePointsPlaced = (handles, gonsteadPoints: string[]) =>
  gonsteadPoints.every((pointName) => {
    const handleName = getHandleName(pointName);

    return handles[handleName] && handles[handleName].placed;
  });

export const drawGonsteadLine = (element, context, handle, lineOptions) => {
  Drawing.drawLine(
    context, //Target context (CanvasRenderingContext2D)
    element, //event.currentTarget, //The DOM Element to draw on (HTMLElement)
    { x: handle.start.x, y: handle.start.y }, //{x,y}
    { x: handle.end.x, y: handle.end.y }, //{x,y}
    lineOptions, //See path
    "canvas"
  );
};

export const calculateGradient = (point1, point2) =>
  (point1.y - point2.y) / (point1.x - point2.x);

export const getDistancesBetweenPoints = (
  point1,
  point2,
  pixelSpacing = { rowPixelSpacing: 1, colPixelSpacing: 1 }
) => getDistance(point1, point2, pixelSpacing);

export const drawMeasurementTextBox = (
  context,
  element,
  handle,
  value,
  handles,
  displayedPoints
) => {
  drawLinkedTextBox(
    context, //The canvas context.
    element, //The element on which to draw the link. HTMLElement
    handle,
    value, //The text to display in the textBox.
    handles, //The handles of the annotation.
    function () {
      return displayedPoints;
    },
    GONSTEAD_CONFIGURATION.measurementColour,
    GONSTEAD_CONFIGURATION.textLineWidth,
    GONSTEAD_CONFIGURATION.xOffsetMeasurementTextbox,
    GONSTEAD_CONFIGURATION.verticallyCenterText //Vertically centers the text if true.
  );
};

export const getTextboxValue = (labelValue: number, suffix, title?) => {
  return `${title ? `${title}:` : ""} ${to2Dp(labelValue)} ${suffix}`;
};

const to2Dp = (value) => {
  return Math.round(value * 100) / 100;
};
