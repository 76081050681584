export const GONSTEAD_POINTS = {
  RIGHT_FEMUR_HEAD: "rightFemurHead",
  LEFT_FEMUR_HEAD: "leftFemurHead",
  RIGHT_ILIAC_CREST: "rightIliacCrest",
  RIGHT_ISCHIAL_TUBEROSITY: "rightIschialTuberosity",
  LEFT_ILIAC_CREST: "leftIliacCrest",
  LEFT_ISCHIAL_TUBEROSITY: "leftIschialTuberosity",
  S2_TUBERCLE: "s2Tubercle",
  PUBIC_SYMPHYSIS: "pubicSymphysis",
  RIGHT_S1_SACRAL_GROOVE: "rightS1SacralGroove",
  LEFT_S1_SACRAL_GROOVE: "leftS1SacralGroove",
  RIGHT_SACRAL_ALA: "rightSacralAla",
  LEFT_SACRAL_ALA: "leftSacralAla",
  RIGHT_ILIAC_WING: "rightIliacWing",
  LEFT_ILIAC_WING: "leftIliacWing",
  RIGHT_PSIS: "rightPSIS",
  LEFT_PSIS: "leftPSIS",
};

export const GONSTEAD_POINTS_ARRAY = [
  GONSTEAD_POINTS.RIGHT_FEMUR_HEAD,
  GONSTEAD_POINTS.LEFT_FEMUR_HEAD,
  GONSTEAD_POINTS.RIGHT_ILIAC_CREST,
  GONSTEAD_POINTS.RIGHT_ISCHIAL_TUBEROSITY,
  GONSTEAD_POINTS.LEFT_ILIAC_CREST,
  GONSTEAD_POINTS.LEFT_ISCHIAL_TUBEROSITY,
  GONSTEAD_POINTS.S2_TUBERCLE,
  GONSTEAD_POINTS.PUBIC_SYMPHYSIS,
  GONSTEAD_POINTS.RIGHT_S1_SACRAL_GROOVE,
  GONSTEAD_POINTS.LEFT_S1_SACRAL_GROOVE,
  GONSTEAD_POINTS.RIGHT_SACRAL_ALA,
  GONSTEAD_POINTS.LEFT_SACRAL_ALA,
  GONSTEAD_POINTS.RIGHT_ILIAC_WING,
  GONSTEAD_POINTS.LEFT_ILIAC_WING,
  GONSTEAD_POINTS.RIGHT_PSIS,
  GONSTEAD_POINTS.LEFT_PSIS,
];

export const Y_OFFSET_POINTS = [
  GONSTEAD_POINTS.RIGHT_SACRAL_ALA,
  GONSTEAD_POINTS.LEFT_SACRAL_ALA,
];

export const GONSTEAD_CONFIGURATION = {
  handleRadius: 2,
  renderDashed: false,
  hideHandlesIfMoving: true,
  prePlacementHandleRadius: 2,
  drawHandlesOnHover: true,
  circleColour: "aqua",
  circleColourOutsideImage: "red",
  labelColour: "aqua",
  measurementColour: "white",
  lineColour: "lime",
  lineColourOutsideImage: "red",
  textLineWidth: 2,
  verticallyCenterText: true,
  xOffsetMeasurementTextbox: 0,
};
