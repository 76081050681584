import { FormattedHandlesData } from "./gonstedTool.types";

export const formatHandles = (currentImagePoints: {
  x: number;
  y: number;
}): FormattedHandlesData => ({
  visible: true,
  active: true,
  color: undefined,
  invalidated: true,
  complete: false,
  value: "",
  handles: {
    rightFemurHeadHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R Femur Head",
      placed: true, // True because this is the first item to be placed
      insideImage: true,
    },
    leftFemurHeadHandle: {
      ...currentImagePoints,
      highlight: true,
      active: true,
      label: "L Femur Head",
      placed: false,
      insideImage: true,
    },
    rightIliacCrestHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R Iliac Crest",
      placed: false,
      insideImage: true,
    },
    rightIschialTuberosityHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R Ischial Tuberosity",
      placed: false,
      insideImage: true,
    },
    leftIliacCrestHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "L Iliac Crest",
      placed: false,
      insideImage: true,
    },
    leftIschialTuberosityHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "L Ischial Tuberosity",
      placed: false,
      insideImage: true,
    },
    s2TubercleHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "S2 Tubercle",
      placed: false,
      insideImage: true,
    },
    pubicSymphysisHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "Pubic Symphysis",
      placed: false,
      insideImage: true,
    },
    rightS1SacralGrooveHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R S1 Sacral Groove",
      placed: false,
      insideImage: true,
    },
    leftS1SacralGrooveHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "L S1 Sacral Groove",
      placed: false,
      insideImage: true,
    },
    rightSacralAlaHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R Sacral Ala",
      placed: false,
      insideImage: true,
    },
    leftSacralAlaHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "L Sacral Ala",
      placed: false,
      insideImage: true,
    },
    rightIliacWingHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R Iliac Wing",
      placed: false,
      insideImage: true,
    },
    leftIliacWingHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "L Iliac Wing",
      placed: false,
      insideImage: true,
    },
    rightPSISHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "R PSIS",
      placed: false,
      insideImage: true,
    },
    leftPSISHandle: {
      ...currentImagePoints,
      highlight: true,
      active: false,
      label: "L PSIS",
      placed: false,
      insideImage: true,
    },

    rightFemurHeadTextBox: {
      handleKey: "rightFemurHeadHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: true,
      insideImage: true,
    },
    leftFemurHeadTextBox: {
      handleKey: "leftFemurHeadHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    rightIliacCrestTextBox: {
      handleKey: "rightIliacCrestHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    rightIschialTuberosityTextBox: {
      handleKey: "rightIschialTuberosityHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    leftIliacCrestTextBox: {
      handleKey: "leftIliacCrestHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    leftIschialTuberosityTextBox: {
      handleKey: "leftIschialTuberosityHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    s2TubercleTextBox: {
      handleKey: "s2TubercleHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    pubicSymphysisTextBox: {
      handleKey: "pubicSymphysisHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    rightS1SacralGrooveTextBox: {
      handleKey: "rightS1SacralGrooveHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    leftS1SacralGrooveTextBox: {
      handleKey: "leftS1SacralGrooveHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    rightSacralAlaTextBox: {
      handleKey: "rightSacralAlaHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    leftSacralAlaTextBox: {
      handleKey: "leftSacralAlaHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    rightIliacWingTextBox: {
      handleKey: "rightIliacWingHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    leftIliacWingTextBox: {
      handleKey: "leftIliacWingHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    rightPSISTextBox: {
      handleKey: "rightPSISHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    leftPSISTextBox: {
      handleKey: "leftPSISHandle",
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },

    //Measurement Handles
    textBoxMD: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_iliacCrestRLine_ischialTuberosityRLine: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_iliacCrestLLine_ischialTuberosityLLine: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_S2TubercleLine_pubicSymphysisLine: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_rightSacralAlaOnSbl_s2TubercleOnSbl: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_leftSacralAlaOnSbl_s2TubercleOnSbl: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_rightIliacWingLine_rightPSISLine: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
    textBox_leftIliacWingLine_leftPSISLine: {
      active: false,
      hasMoved: false,
      movesIndependently: true,
      drawnIndependently: true,
      allowedOutsideImage: true,
      hasBoundingBox: true,
      placed: false,
      insideImage: true,
    },
  },
  prePlacementHandles: {
    handle: {
      ...currentImagePoints,
      highlight: true,
      active: true,
      label: "",
      placed: false,
      insideImage: true,
    },
  },
});
