import { GONSTEAD_POINTS } from "./gonsteadTool.consts";

export const setToolInstructions = (instructionKey: string) => {
  const toolInstructions = document.getElementById("toolInstructions");
  if (toolInstructions) {
    switch (instructionKey) {
      case GONSTEAD_POINTS.RIGHT_FEMUR_HEAD:
        toolInstructions.innerHTML =
          "1. Click the highest point of the right femur head";
        break;
      case GONSTEAD_POINTS.LEFT_FEMUR_HEAD:
        toolInstructions.innerHTML =
          "2. Click the highest point of the left femur head";
        break;
      case GONSTEAD_POINTS.RIGHT_ILIAC_CREST:
        toolInstructions.innerHTML = "3. Place the Right Iliac Crest.";
        break;
      case GONSTEAD_POINTS.RIGHT_ISCHIAL_TUBEROSITY:
        toolInstructions.innerHTML =
          "4. Click the lowest point of the right ischial tuberosity";
        break;
      case GONSTEAD_POINTS.LEFT_ILIAC_CREST:
        toolInstructions.innerHTML = "Place the Left Iliac Crest.";
        break;
      case GONSTEAD_POINTS.LEFT_ISCHIAL_TUBEROSITY:
        toolInstructions.innerHTML = "Place the Left Ischial Tuberosity.";
        break;
      case GONSTEAD_POINTS.S2_TUBERCLE:
        toolInstructions.innerHTML = "Place the S2 Tubercle.";
        break;
      case GONSTEAD_POINTS.PUBIC_SYMPHYSIS:
        toolInstructions.innerHTML = "Place the Pubic Symphysis.";
        break;
      case GONSTEAD_POINTS.RIGHT_S1_SACRAL_GROOVE:
        toolInstructions.innerHTML = "Place the Right S1 Sacral Groove.";
        break;
      case GONSTEAD_POINTS.LEFT_S1_SACRAL_GROOVE:
        toolInstructions.innerHTML = "Place the Left S1 Sacral Groove.";
        break;
      case GONSTEAD_POINTS.RIGHT_SACRAL_ALA:
        toolInstructions.innerHTML = "Place the Right Sacral Ala.";
        break;
      case GONSTEAD_POINTS.LEFT_SACRAL_ALA:
        toolInstructions.innerHTML = "Place the Left Sacral Ala.";
        break;
      case GONSTEAD_POINTS.RIGHT_ILIAC_WING:
        toolInstructions.innerHTML = "Place the Right Iliac Wing.";
        break;
      case GONSTEAD_POINTS.LEFT_ILIAC_WING:
        toolInstructions.innerHTML = "Place the Left Iliac Wing.";
        break;
      case GONSTEAD_POINTS.RIGHT_PSIS:
        toolInstructions.innerHTML = "Place the Right PSIS.";
        break;
      case GONSTEAD_POINTS.LEFT_PSIS:
        toolInstructions.innerHTML = "Place the Left PSIS.";
        break;
      default:
        toolInstructions.innerHTML = "";
        break;
    }
  }
};
