import { convertPointsToPixels } from "../../measurement-tools/measurementToolUtils";
import { GONSTEAD_POINTS } from "./gonsteadTool.consts";
import { getDistancesBetweenPoints } from "./helpers";

export const calculateTubercleLine = (
  handleCanvasMap,
  fhlGradient,
  fhlPerpGradient,
  sblGradient,
  xExtPerp
) => {
  // Calculate the S2 Tubercle Line
  const s2TubercleStartX =
    (fhlPerpGradient * handleCanvasMap.s2Tubercle.x -
      handleCanvasMap.s2Tubercle.y -
      sblGradient * handleCanvasMap.rightS1SacralGroove.x +
      handleCanvasMap.rightS1SacralGroove.y) /
    (fhlPerpGradient - sblGradient);

  const s2TubercleStartY =
    (sblGradient * fhlPerpGradient * handleCanvasMap.s2Tubercle.x -
      sblGradient * handleCanvasMap.s2Tubercle.y -
      fhlPerpGradient * sblGradient * handleCanvasMap.rightS1SacralGroove.x +
      fhlPerpGradient * handleCanvasMap.rightS1SacralGroove.y) /
    (fhlPerpGradient - sblGradient);

  const s2TubercleEndX =
    (fhlPerpGradient * handleCanvasMap.s2Tubercle.x -
      handleCanvasMap.s2Tubercle.y -
      fhlGradient * handleCanvasMap.pubicSymphysis.x +
      handleCanvasMap.pubicSymphysis.y) /
    (fhlPerpGradient - fhlGradient); // X coordinate of point perpendicular to pubicSymphysis

  const s2TubercleEndY =
    (fhlGradient * fhlPerpGradient * handleCanvasMap.s2Tubercle.x -
      fhlGradient * handleCanvasMap.s2Tubercle.y -
      fhlGradient * fhlPerpGradient * handleCanvasMap.pubicSymphysis.x +
      fhlPerpGradient * handleCanvasMap.pubicSymphysis.y) /
    (fhlPerpGradient - fhlGradient);

  const tubercleStartPoint = { x: s2TubercleStartX, y: s2TubercleStartY };
  const s2Tubercle = {
    start: { x: s2TubercleStartX, y: s2TubercleStartY },
    end: {
      x:
        fhlGradient < 0 ? s2TubercleEndX + xExtPerp : s2TubercleEndX - xExtPerp,
      y:
        fhlGradient < 0
          ? s2TubercleEndY + fhlPerpGradient * xExtPerp
          : s2TubercleEndY - fhlPerpGradient * xExtPerp,
    },
  };
  return {
    s2TubercleEndX,
    s2TubercleEndY,
    s2TubercleStartX,
    s2TubercleStartY,
    tubercleStartPoint,
    s2Tubercle,
  };
};
export const calculateSacralAlaData = (
  handleCanvasMap,
  sideKey: string,
  fhlPerpGradient,
  sblGradient,
  fhlGradient,
  xExtPerp
) => {
  const sacralAlaStartX =
    (fhlPerpGradient * handleCanvasMap[`${sideKey}SacralAla`].x -
      handleCanvasMap[`${sideKey}SacralAla`].y -
      sblGradient * handleCanvasMap[`${sideKey}S1SacralGroove`].x +
      handleCanvasMap[`${sideKey}S1SacralGroove`].y) /
    (fhlPerpGradient - sblGradient);

  const sacralAlaStartY =
    (sblGradient * fhlPerpGradient * handleCanvasMap[`${sideKey}SacralAla`].x -
      sblGradient * handleCanvasMap[`${sideKey}SacralAla`].y -
      fhlPerpGradient *
        sblGradient *
        handleCanvasMap[`${sideKey}S1SacralGroove`].x +
      fhlPerpGradient * handleCanvasMap[`${sideKey}S1SacralGroove`].y) /
    (fhlPerpGradient - sblGradient);

  // Calculate the Right Sacral Ala Line
  const sacralAlaLine = {
    start: { x: sacralAlaStartX, y: sacralAlaStartY },
    end: {
      x:
        fhlGradient < 0
          ? handleCanvasMap[`${sideKey}SacralAla`].x + xExtPerp
          : handleCanvasMap[`${sideKey}SacralAla`].x - xExtPerp,
      y:
        fhlGradient < 0
          ? handleCanvasMap[`${sideKey}SacralAla`].y +
            fhlPerpGradient * xExtPerp
          : handleCanvasMap[`${sideKey}SacralAla`].y -
            fhlPerpGradient * xExtPerp,
    },
  };
  const sacralAlaStartPoint = {
    x: sacralAlaStartX,
    y: sacralAlaStartY,
  };
  return {
    sacralAlaLine,
    sacralAlaStartX,
    sacralAlaStartY,
    sacralAlaStartPoint,
  };
};

export const calculateMeasuredDeficiency = (
  handleCanvasMap,
  xExt,
  element,
  pixelSpacing
) => {
  const mdPoint =
    handleCanvasMap.rightFemurHead.y <= handleCanvasMap.leftFemurHead.y
      ? {
          x: handleCanvasMap.leftFemurHead.x,
          y: handleCanvasMap.rightFemurHead.y,
        }
      : {
          x: handleCanvasMap.rightFemurHead.x,
          y: handleCanvasMap.leftFemurHead.y,
        };
  const measuredDeficiencyLine = {
    start: { x: mdPoint.x - xExt, y: mdPoint.y },
    end: { x: mdPoint.x + xExt, y: mdPoint.y },
  };

  const pointsToConvert = {
    leftFemurHead: handleCanvasMap.leftFemurHead,
    rightFemurHead: handleCanvasMap.rightFemurHead,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);
  const measuredDeficiencyDistance =
    Math.abs(asPixels.leftFemurHead.y - asPixels.rightFemurHead.y) *
    pixelSpacing.rowPixelSpacing;

  return { measuredDeficiencyLine, measuredDeficiencyDistance, mdPoint };
};

export const calculateIliacCrestData = (
  handleCanvasMap,
  sideKey,
  fhlGradient,
  fhlPerpGradient,
  element,
  pixelSpacing
) => {
  const iliacCrestPerpendicularPoint = {
    x:
      (fhlPerpGradient * handleCanvasMap[`${sideKey}IliacCrest`].x -
        fhlGradient * handleCanvasMap[`${sideKey}FemurHead`].x +
        handleCanvasMap[`${sideKey}FemurHead`].y -
        handleCanvasMap[`${sideKey}IliacCrest`].y) /
      (fhlPerpGradient - fhlGradient),
    y:
      (fhlPerpGradient * handleCanvasMap[`${sideKey}FemurHead`].y -
        fhlPerpGradient *
          fhlGradient *
          handleCanvasMap[`${sideKey}FemurHead`].x +
        fhlPerpGradient *
          fhlGradient *
          handleCanvasMap[`${sideKey}IliacCrest`].x -
        fhlGradient * handleCanvasMap[`${sideKey}IliacCrest`].y) /
      (fhlPerpGradient - fhlGradient),
  };
  const pointsToConvert = {
    [`${sideKey}IliacCrest`]: handleCanvasMap[`${sideKey}IliacCrest`],
    iliacCrestPerpendicularPoint,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);
  const distanceIliacCrestLineFHL = getDistancesBetweenPoints(
    asPixels[`${sideKey}IliacCrest`],
    asPixels.iliacCrestPerpendicularPoint,
    pixelSpacing
  );
  return { distanceIliacCrestLineFHL, iliacCrestPerpendicularPoint };
};

export const calculateIschialTuberosityData = (
  handleCanvasMap,
  sideKey,
  fhlGradient,
  fhlPerpGradient,
  element,
  pixelSpacing
) => {
  const perpendicularPointIschialTuberosity = {
    x:
      (fhlPerpGradient * handleCanvasMap[`${sideKey}IschialTuberosity`].x -
        fhlGradient * handleCanvasMap[`${sideKey}FemurHead`].x +
        handleCanvasMap[`${sideKey}FemurHead`].y -
        handleCanvasMap[`${sideKey}IschialTuberosity`].y) /
      (fhlPerpGradient - fhlGradient),
    y:
      (fhlPerpGradient * handleCanvasMap[`${sideKey}FemurHead`].y -
        fhlPerpGradient *
          fhlGradient *
          handleCanvasMap[`${sideKey}FemurHead`].x +
        fhlPerpGradient *
          fhlGradient *
          handleCanvasMap[`${sideKey}IschialTuberosity`].x -
        fhlGradient * handleCanvasMap[`${sideKey}IschialTuberosity`].y) /
      (fhlPerpGradient - fhlGradient),
  };
  const pointsToConvert = {
    [`${sideKey}IschialTuberosity`]:
      handleCanvasMap[`${sideKey}IschialTuberosity`],
    perpendicularPointIschialTuberosity,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);
  const ischialTuberosityDistance = getDistancesBetweenPoints(
    asPixels[`${sideKey}IschialTuberosity`],
    asPixels.perpendicularPointIschialTuberosity,
    pixelSpacing
  );

  return { ischialTuberosityDistance, perpendicularPointIschialTuberosity };
};

export const calculateIliacCrestToTuberosityDistance = (
  iliacCrestPerpendicularPoint,
  ischialTuberosityPerpendicularPoint,
  element,
  pixelSpacing
) => {
  const pointsToConvert = {
    iliacCrestPerpendicularPoint,
    ischialTuberosityPerpendicularPoint,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);
  const distanceIliacCrestToIschialTuberosityLine = getDistancesBetweenPoints(
    asPixels.iliacCrestPerpendicularPoint,
    asPixels.ischialTuberosityPerpendicularPoint,
    pixelSpacing
  );
  return distanceIliacCrestToIschialTuberosityLine;
};

export const calculateTubercleToPubicSymphysis = (
  handleCanvasMap,
  fhlGradient,
  fhlPerpGradient,
  element,
  pixelSpacing
) => {
  const perpendicularPointS2TuberclePubicSymphysis = {
    x:
      (fhlPerpGradient * handleCanvasMap.s2Tubercle.x -
        fhlGradient * handleCanvasMap.pubicSymphysis.x -
        handleCanvasMap.s2Tubercle.y +
        handleCanvasMap.pubicSymphysis.y) /
      (fhlPerpGradient - fhlGradient),
    y:
      (fhlPerpGradient * handleCanvasMap.pubicSymphysis.y -
        fhlGradient * handleCanvasMap.s2Tubercle.y +
        fhlPerpGradient * fhlGradient * handleCanvasMap.s2Tubercle.x -
        fhlPerpGradient * fhlGradient * handleCanvasMap.pubicSymphysis.x) /
      (fhlPerpGradient - fhlGradient),
  };
  const pointsToConvert = {
    [GONSTEAD_POINTS.PUBIC_SYMPHYSIS]: handleCanvasMap.pubicSymphysis,
    perpendicularPointS2TuberclePubicSymphysis,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);

  const distanceS2TuberclePubicSymphysis = getDistancesBetweenPoints(
    asPixels.pubicSymphysis,
    asPixels.perpendicularPointS2TuberclePubicSymphysis,
    pixelSpacing
  );
  return {
    perpendicularPointS2TuberclePubicSymphysis,
    distanceS2TuberclePubicSymphysis,
  };
};

export const calculateSacralAlaToTubercleData = (
  sacralAlaStartPoint,
  tubercleStartPoint,
  element,
  pixelSpacing
) => {
  const pointsToConvert = {
    sacralAlaStartPoint,
    tubercleStartPoint,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);

  const sacralAlaOnSblS2TubercleOnSblDistance = getDistancesBetweenPoints(
    asPixels.sacralAlaStartPoint,
    asPixels.tubercleStartPoint,
    pixelSpacing
  );
  return sacralAlaOnSblS2TubercleOnSblDistance;
};

export const calculateIliacWingPSISData = (
  handleCanvasMap,
  sideKey,
  fhlGradient,
  fhlPerpGradient,
  element,
  pixelSpacing
) => {
  const perpendicularPoint = {
    x:
      (fhlPerpGradient * handleCanvasMap[`${sideKey}IliacWing`].x -
        fhlGradient * handleCanvasMap[`${sideKey}PSIS`].x -
        handleCanvasMap[`${sideKey}IliacWing`].y +
        handleCanvasMap[`${sideKey}PSIS`].y) /
      (fhlPerpGradient - fhlGradient),
    y:
      (fhlPerpGradient * handleCanvasMap[`${sideKey}PSIS`].y -
        fhlGradient * handleCanvasMap[`${sideKey}IliacWing`].y +
        fhlPerpGradient *
          fhlGradient *
          handleCanvasMap[`${sideKey}IliacWing`].x -
        fhlPerpGradient * fhlGradient * handleCanvasMap[`${sideKey}PSIS`].x) /
      (fhlPerpGradient - fhlGradient),
  };

  const pointsToConvert = {
    [`${sideKey}PSIS`]: handleCanvasMap[`${sideKey}PSIS`],
    perpendicularPoint,
  };
  const asPixels = convertPointsToPixels(element, pointsToConvert);

  const distanceIliacWingLineToPSISLine = getDistancesBetweenPoints(
    asPixels[`${sideKey}PSIS`],
    asPixels.perpendicularPoint,
    pixelSpacing
  );

  return { perpendicularPoint, distanceIliacWingLineToPSISLine };
};
