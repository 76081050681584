import { TOOL_IDS } from "../consts/tools.consts";
import { checkEventData } from "../functions/eventHandlingValidation";
import { ExtendedAnnotationTool } from "../api/ExtendedAnnotationTool";
import cornerstone from "cornerstone-core";
import { INITIAL_MPR_TOOL_STATE } from "./mprTool.consts";
import cornerstoneTools from "cornerstone-tools";

export class MPRTool extends ExtendedAnnotationTool {
  contextFunction:
    | null
    | ((toolId: string, data: { x: number; y: number }) => void);
  constructor() {
    super(TOOL_IDS.MPR_TOOL);
    this.contextFunction = null;
    this.setContextFunction = this.setContextFunction.bind(this);
  }

  public setContextFunction(contextFunction) {
    this.contextFunction = contextFunction;
  }

  updateCachedStats(_image: any, element: any, toolData: any): void {}
  createNewMeasurement(event) {
    const isEventDataValid = checkEventData(event);
    if (
      isEventDataValid &&
      (event.event.type === "touchstart" || event.event.type === "mousedown")
    ) {
      cornerstoneTools.clearToolState(event.element, this.toolId);

      return {
        ...INITIAL_MPR_TOOL_STATE,
        handles: {
          start: event.currentPoints.image,
          end: event.currentPoints.image,
        },
        isClicked: true,
      };
    }
    return;
  }

  pointNearTool(_element, _data, _coords) {
    return false;
  }

  drawToolData(element, context, toolData) {
    if (element.id !== "viewport-0-mpr-overlay") {
      return;
    }
    const canvasWidth = context.canvas.width;
    const canvasHeight = context.canvas.height;

    const {
      handles: { start },
      isClicked,
    } = toolData;
    if (start.x === -1 && start.y === -1) {
      start.x = canvasWidth / 2;
      start.y = canvasHeight / 2;
    }
    const renderedPosition = isClicked
      ? cornerstone.pixelToCanvas(element, start)
      : start;
    const mprPosition = isClicked
      ? start
      : cornerstone.canvasToPixel(element, start);

    context.clearRect(0, 0, canvasWidth, canvasHeight);

    // Draw horizontal line
    context.beginPath();
    context.strokeStyle = "red";
    context.moveTo(0, renderedPosition.y);
    context.lineTo(canvasWidth, renderedPosition.y);
    context.stroke();

    // Draw vertical line
    context.beginPath();
    context.moveTo(renderedPosition.x, 0);
    context.lineTo(renderedPosition.x, canvasHeight);
    context.stroke();

    // Draw intersection point
    context.beginPath();
    context.fillStyle = "red";
    context.arc(renderedPosition.x, renderedPosition.y, 3, 0, 2 * Math.PI);
    context.fill();

    // Display labels with x and y coordinates
    context.fillStyle = "greenyellow";
    context.font = "15px Arial";
    const text = `X: ${mprPosition.x.toFixed(2)}, Y : ${mprPosition.y.toFixed(
      2
    )}`;

    context.fillText(text, renderedPosition.x + 5, renderedPosition.y - 5);
    if (this.contextFunction) {
      this.contextFunction(TOOL_IDS.MPR_TOOL, mprPosition);
    }
  }
}
